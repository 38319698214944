import React from "react"
import { Link } from "gatsby"
import logo from './membisnis.png'

class Sidebar extends React.Component {
  render() {
    return (
      <>
        
        <div className="col-lg-4 col-md-12 sidenav">
                <div className="sidebar sticky-top sdbr">
              
                  <Link className="nava_logo" to="/">
                   <img className="nav_logo_img" src={logo} alt="SUKSES-MEMBISNIS" />
                  </Link>
                </div>
                <div className="thecategory">
                Daftarkan Olymp Trade Dapatkan $ 10.000 gratis ke akun DEMO
                    </div>
                <div className="sidebar sticky-top sdbr">
              {<iframe src="https://official.olymptrade-idr.com/lands/affiliate-new-form/id.index.html?lang=id&dark=false&square=false&horizontal=false&subid1=membisnis-form&subid2=&affiliate_id=1060014&no_redirect=1&freereg=false&auth=false" width="100%" height="320" frameborder="0"></iframe>}
              
              </div>
        </div>
      </>
    )
  }
}

export default Sidebar