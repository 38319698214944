import React, { Fragment } from "react"
// import { Link } from "gatsby"
// import Header from "./Header";
// import logo from './membisnis.png'
//import Siderbar from "./Sidebar";
//import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footeren from "./Footeren";
import "./Layout.css";
import Sidebar from "./Sidebar";

class Layouten extends React.Component {
  render() {
    const { children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`
    // let header

    // if (location.pathname === rootPath) {
    //   header = (
    //     <h1>
    //       <Link className="nava_logo" to="/">
    //         <img className="nav_logo_img" src={logo} alt="SUKSES-MEMBISNIS" />
    //         <p className="pth">SUKSES-MEMBISNIS</p>
    //       </Link>
    //     </h1>
    //   )
    // } else {
    //   header = (
    //     <span>
    //       <Link className="nava_logo" to="/">
    //         <img className="nav_logo_img" src={logo} alt="SUKSES-MEMBISNIS" />
    //       </Link>
    //     </span>
    //   )
    // }
    return (
      <Fragment>
        {/* <Header tagName={header} /> */}
        <div className="page_wrapper container">
          <div className="row">
            <Sidebar />
            {children}
          </div>
        </div>
        <Footeren />
      </Fragment>
    )
  }
}

export default Layouten
